<template>
  <div id="mapDiv">
    <!-- 百度地图  -->
    <div id="bai-du-map">
      <!-- 技术支持和联系方式  -->
    </div>
    <div class="map-title" v-if="isMap">
      <div>地图加载中...</div>
      <div>如没长时间还未进入请退出重新进入</div>
    </div>
    <div class="setAlgt" v-if="!isDzwl">该公司暂未设置地理围栏</div>
    <div class="utils-box" style="right: 10px">
      <el-input
        :id="iptId"
        size="mini"
        v-model="accdis"
        style="width: 45%; margin-right: 10px"
        placeholder="搜索准确地名"
      ></el-input>
      <el-button type="primary" round size="mini" @click="setCircleState">
        编辑范围
      </el-button>
      <el-button type="primary" round size="mini" @click="setCircleEnd"
        >结束编辑</el-button
      >
    </div>
    <div class="utils-box" style="left: 10px">
      <div class="radius-box">
        <div style="margin-right: 10px">设置范围半径(m)：</div>
        <el-input
          :id="iptId"
          size="mini"
          @input="setRadius"
          v-model="radius"
          style="flex: 1; margin-right: 10px"
          placeholder="输入半径范围"
        ></el-input>
      </div>
    </div>
    <div class="utils-box2">
      <el-button type="success" size="mini" @click="saveDzwl"
        >保存地理围栏</el-button
      >
      <el-button type="danger" size="mini" @click="delDzwl"
        >删除地理围栏</el-button
      >
    </div>
  </div>
</template>
 
 <script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import AMapLoader from "@amap/amap-jsapi-loader";
import {
  selectFenceDzwl,
  createFenceDzwl,
  updateFenceDzwl,
  deleteFenceDzwl,
} from "@/api/dzwl";

export default {
  props: ["companyName"],
  name: "dzwl",
  data() {
    return {
      isDzwl: false,
      isMap: true,
      radius: 100, // 半径
      accdis: "",
      iptId: "seachId",
      map: null,
      mouseTool: null,
      overlays: [],
      auto: null,
      placeSearch: null,
      circleEditor: null,
      queryObj: null,
      coordinate: [101.684447, 36.937061], // 坐标点 默认天府广场
    };
  },
  created() {},
  async mounted() {
    console.log("当前公司信息", this.companyName);
    this.radius = 100;
    this.coordinate = [101.684447, 36.937061];
    await this.queryDzwl();
    //DOM初始化完成进行地图初始化
    await this.initMap();
  },
  methods: {
    // 高德经纬度转百度
    // let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
    // ga_bd(106.506296,29.620539)
    ga_bd(gd_lon, gd_lat) {
      let x = Number(gd_lon);
      let y = Number(gd_lat);
      let z = Number(Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi));
      let theta = Number(Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi));
      let bd_lon = Number(z * Math.cos(theta) + 0.0065);
      let bd_lat = Number(z * Math.sin(theta) + 0.006);
      console.log(bd_lon);
      console.log(bd_lat);
    },
    // 删除地理围栏
    async delDzwl() {
      let params = {
        id: this.queryObj.id,
      };
      let res = await deleteFenceDzwl(params);
      // if (res.data.statusCode == "00000") {
        // Message.success(res.data.message);
        Message.error("删除成功");
        this.queryDzwl();
        this.$emit("getOpenDzwl", false); // 触发父组件的方法，并传递参数index
      // } else {
        // Message.error(res.data.message);
      // }

    },
    // 保存(创建)地理围栏
    async saveDzwl() {
      if (!this.isDzwl) {
        let params = {
          fence_name: this.companyName.realname + "(地理围栏)",
          companyId: this.companyName.id,
          radius: this.radius,
          latitude: this.coordinate[1],
          longitude: this.coordinate[0],
          companyName: this.companyName.realname,
        };
        let res = await createFenceDzwl(params);
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.queryDzwl();
          this.$emit("getOpenDzwl", false); // 触发父组件的方法，并传递参数index
        } else {
          Message.error(res.data.message);
        }
      } else {
        await this.modifyDzwl();
      }
    },
    // 修改地理围栏
    async modifyDzwl() {
      let params = {
        // fence_name: this.companyName.realname + "(地理围栏)",
        id: this.queryObj.id,
        radius: this.radius,
        latitude: this.coordinate[1],
        longitude: this.coordinate[0],
      };
      let res = await updateFenceDzwl(params);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
        this.queryDzwl();
        this.$emit("getOpenDzwl", false); // 触发父组件的方法，并传递参数index
      } else {
        Message.error(res.data.message);
      }
    },
    // 查询公司地理围栏
    async queryDzwl() {
      let params = {
        page: 1,
        size: 10,
      };
      this.companyName.companyId
        ? (params.companyId = this.companyName.companyId)
        : (params.companyId = this.companyName.id);
      let res = await selectFenceDzwl(params);
      // console.log("查询出的信息", res.data.data[0]);
      if (res.data.data.length != 0) {
        let data = res.data.data[0];
        this.isDzwl = true;
        this.queryObj = data;
        this.radius = data.radius;
        this.coordinate = [data.longitude, data.latitude];
      } else {
        this.isDzwl = false;
        this.queryObj = null;
      }
    },
    // 通过输入框设置半径
    setRadius() {
      this.radius = this.radius.replace(/^0+(\d)|[^\d]+/g, "");
      this.drawCircle(this.coordinate);
    },
    // 编辑开始
    setCircleState() {
      this.circleEditor.close();
      this.circleEditor.open();
      console.log("this.circleEditor", this.circleEditor);
    },
    // 编辑结束
    setCircleEnd() {
      this.circleEditor.close();
    },
    // 初始化地图
    initMap() {
      AMapLoader.load({
        key: "d3ef7ad351416f1e203a88e69180e014",
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.ToolBar",
          "AMap.ControlBar",
          "AMap.MouseTool",
          "AMap.MapType",
          "AMap.HawkEye",
          "AMap.CircleEditor",
          "AMap.Geolocation",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          // 初始化地图
          this.map = new AMap.Map("bai-du-map", {
            viewMode: "2D", //  是否为3D地图模式
            zoom: 13, // 初始化地图级别
            center: this.coordinate, //中心点坐标  郑州
            resizeEnable: true,
          });

          AMap.plugin("AMap.Geolocation", function () {
            var geolocation = new AMap.Geolocation({
              // 是否使用高精度定位，默认：true
              enableHighAccuracy: true,
              // 设置定位超时时间，默认：无穷大
              timeout: 10000,
              // 定位按钮的停靠位置的偏移量
              offset: [10, 20],
              //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
              zoomToAccuracy: true,
              //  定位按钮的排放位置,  RB表示右下
              position: "RB",
            });

            geolocation.getCurrentPosition(function (status, result) {
              if (status == "complete") {
                onComplete(result);
              } else {
                onError(result);
              }
            });

            function onComplete(data) {
              // data是具体的定位信息
              console.log("data是具体的定位信息", data);
            }

            function onError(data) {
              // 定位出错
              console.log("定位出错");
            }
          });
          // 搜索功能
          this.auto = new AMap.AutoComplete({
            input: this.iptId, // 搜索框的id
          });
          this.placeSearch = new AMap.PlaceSearch({
            map: this.map,
            panel: "panel", // 结果列表将在此容器中进行展示。
            // city: "010", // 兴趣点城市
            autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
            extensions: "base", //返回基本地址信息
          });
          this.auto.on("select", this.select); //注册监听，当选中某条记录时会触发

          //   添加多个工具
          this.map.addControl(new AMap.Scale()); // 添加左下角的比例尺
          let toolBar = new AMap.ToolBar({
            position: {
              top: "80px",
              left: "110px",
            },
          });
          let controlBar = new AMap.ControlBar({
            position: {
              top: "130px",
              right: "10px",
            },
          });
          let MapType = new AMap.MapType({
            position: {
              top: "80px",
              left: "100px",
            },
          });
          this.map.addControl(toolBar); // 添加右下角的放大缩小
          this.map.addControl(controlBar); // 方向盘
          this.map.addControl(MapType); // 添加右上角的标准图和卫星图  和路况
          this.map.addControl(new AMap.HawkEye()); // 添加地图放大镜

          // 鼠标点击获取经纬度
          this.map.on("click", (e) => {
            console.log("经度", e.lnglat.getLng());
            console.log("纬度", e.lnglat.getLat());
            this.circleEditor.close();
            this.coordinate = [e.lnglat.getLng(), e.lnglat.getLat()];
            this.drawCircle(this.coordinate);
          });

          this.drawCircle(this.coordinate);

          this.isMap = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 圆的绘制
    drawCircle(pos) {
      this.map.clearMap();
      // 圆的绘制
      var circle = new AMap.Circle({
        center: pos,
        radius: this.radius, //半径
        borderWeight: 3,
        strokeColor: "#FF33FF",
        strokeOpacity: 1,
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        strokeStyle: "dashed",
        strokeDasharray: [10, 10],
        // 线样式还支持 'dashed'
        fillColor: "#1791fc",
        zIndex: 10,
      });
      // circle.setMap(this.map);
      this.map.add(circle);
      // 缩放地图到合适的视野级别
      this.map.setFitView([circle]);

      this.circleEditor = new AMap.CircleEditor(this.map, circle);
      // console.log("this.circleEditor007", this.circleEditor);

      this.circleEditor.on("move", function (event) {
        console.log("触发事件：move");
      });

      this.circleEditor.on("adjust", function (event) {
        console.log("触发事件：adjust");
      });

      this.circleEditor.on("end", function (event) {
        console.log("触发事件： end");
        // event.target 即为编辑后的圆形对象
      });
    },
    // 选择搜索的地名并定位
    select(e) {
      this.placeSearch.setCity(e.poi.adcode);
      this.placeSearch.search(e.poi.name); //关键字查询查询
      console.log("选择搜索的地名并定位", [
        e.poi.location.lng,
        e.poi.location.lat,
      ]);
      if (!e.poi.location.lng) {
        Message.error("请输入准确地名,否则无法定位");
        return false;
      }

      this.coordinate = [e.poi.location.lng, e.poi.location.lat];
      this.drawCircle(this.coordinate);
      // console.log("选择搜索的地名并定位", e);
      // lng、lat分别代表经度、纬度值;

      // this.drawCircle(e.poi.location.pos)
    },
    // 绘制标记点、圆形、曲线、矩形和多边形等,及其绘制清除
    draw(type) {
      switch (type) {
        case "marker": {
          this.mouseTool.marker({
            //同Marker的Option设置
          });
          break;
        }
        case "polyline": {
          this.mouseTool.polyline({
            strokeColor: "#80d8ff",
            //同Polyline的Option设置
          });
          break;
        }
        case "polygon": {
          this.mouseTool.polygon({
            fillColor: "#00b0ff",
            strokeColor: "#80d8ff",
            //同Polygon的Option设置
          });
          break;
        }
        case "rectangle": {
          this.mouseTool.rectangle({
            fillColor: "#00b0ff",
            strokeColor: "#80d8ff",
            //同Polygon的Option设置
          });
          break;
        }
        // 绘制圆形
        case "circle": {
          this.mouseTool.circle({
            fillColor: "#00b0ff",
            strokeColor: "#80d8ff",
            //同Circle的Option设置
          });
          break;
        }
      }
    },
  },
};
</script>
 
 <style lang="less" scoped>
#mapDiv {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .setAlgt {
    position: absolute;
    top: 20px;
    left: 46%;
    background-color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: bold;
    padding: 12px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  }
  #bai-du-map {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "微软雅黑";
  }
  .map-title {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    text-align: center;
    line-height: 400px;
    background-color: #fff;
  }
  .utils-box {
    position: absolute;
    top: 10px;

    padding: 12px;
    box-sizing: border-box;
    background-color: #fff;
  }
  .utils-box2 {
    position: absolute;
    top: 68px;
    right: 0;
    padding: 12px;
    box-sizing: border-box;
    background-color: transparent;
  }
  .radius-box {
    display: flex;
    align-items: center;
  }
}
/* 隐藏高德logo  */
.amap-logo {
  display: none !important;
}
/* 隐藏高德版权  */
.amap-copyright {
  display: none !important;
}
</style>
 