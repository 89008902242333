<template>
  <el-card id="creatSeat">
    <header>
      <h1>
        地理围栏管理<span style="font-size: 14px; font-weight: bold"
          >(仅展示已开通地理围栏的公司)</span
        >
      </h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>公司名称</span>
            <el-input
              v-model="companyName"
              placeholder="请输入公司名称"
              clearable
              size="mini"
            ></el-input>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            class="searchBtn"
            size="mini"
            >查找</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            class="reset"
            size="mini"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="v in TableTitleArr"
          :prop="v.prop"
          :label="v.label"
          align="center"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="auto"
          align="center"
          style="dispaly: flex"
        >
          <template #default="scope">
            <div class="button-list">
              <el-button
                type="primary"
                @click="editRadius(scope.row)"
                size="mini"
                >编辑</el-button
              >
              <el-button
                type="primary"
                @click="opendzwl(scope.row)"
                size="mini"
                style="margin-right: 10px"
                >地图编辑</el-button
              >
              <el-popconfirm
                confirm-button-text="确认"
                cancel-button-text="点错了"
                @confirm="delRadius(scope.row)"
                icon="el-icon-info"
                :title="`确认要删除 ${scope.row.companyName} 的地理围栏吗?`"
              >
                <el-button type="primary" size="mini" slot="reference"
                  >删除</el-button
                >
              </el-popconfirm>
              <!-- <el-button type="primary" @click="" size="mini">删除</el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 编辑 -->
    <el-dialog
      title="编辑"
      :visible.sync="editVisible"
      @close="closeEditDialog"
    >
      <el-form label-width="120px">
        <el-form-item label="地理围栏半径(m)">
          <el-input v-model="radius" placeholder="设置地理围栏半径"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="isSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 退出电子围栏按钮 -->
    <el-button v-if="openDzwl" class="quit-btn" @click="quitDzwl"
      >退出地理围栏</el-button
    >
    <!-- 电子围栏 -->
    <dzwl
      v-if="openDzwl"
      @getOpenDzwl="getOpenDzwl"
      :companyName="currentCompany"
    ></dzwl>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  selectFenceDzwl,
  createFenceDzwl,
  updateFenceDzwl,
  deleteFenceDzwl,
} from "@/api/dzwl";
// 导入电子围栏
import dzwl from "@/views/dzwl/dzwl";
export default {
  components: {
    dzwl,
  },
  data() {
    return {
      openDzwl: false,
      currentCompany: null,
      radius: null,
      editVisible: false,
      currentRow: null,
      companyName: null,
      options: [],
      seatVisible: false,
      TableTitleArr: [
        {
          prop: "companyName",
          label: "公司名称",
        },
        // {
        //   prop: "status",
        //   label: "围栏状态",
        // },
        {
          prop: "radius",
          label: "围栏半径范围(m)",
        },
        {
          prop: "longitude",
          label: "公司经度",
        },
        {
          prop: "latitude",
          label: "公司纬度",
        },
      ],
      fileList: [],
      encryption: false,
      realname: null,
      roleName: null,
      parentld: null,
      id: null,
      name: null,
      value: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  async created() {
    this.parentld = localStorage.getItem("parentld");
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.encryption = localStorage.getItem("encryption");
    await this.getList();
  },
  methods: {
    // 地理围栏关闭(组件控制)
    getOpenDzwl(bool) {
      this.getList()
      this.openDzwl = bool;
    },
    // 退出地理围栏
    quitDzwl() {
      this.openDzwl = false;
    },
    // 打开地理围栏
    opendzwl(row) {
      this.currentCompany = row;
      console.log("当前公司", row);
      this.openDzwl = true;
    },
    // 确认编辑
    async isSubmit() {
      let params = {
        id: this.currentRow.id,
        // fence_name//围栏名称
        radius: this.radius, //半径
        latitude: this.currentRow.latitude, //围栏圆心纬度
        longitude: this.currentRow.longitude, //围栏圆心经度
      };
      let res = await updateFenceDzwl(params);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
        this.getList();
        this.editVisible = false;
      } else {
        Message.error(res.data.message);
      }
    },
    // 关闭弹框
    closeEditDialog() {
      this.radius = null;
    },
    // 编辑半径
    editRadius(row) {
      this.currentRow = row;
      this.radius = row.radius;
      this.editVisible = true;
    },
    // 删除
    async delRadius(row) {
      let params = {
        id: row.id,
      };
      let res = await deleteFenceDzwl(params);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
        this.getList();
      } else {
        Message.error(res.data.message);
      }
    },
    // 查找
    handleSearch() {
      this.page = 1;
      this.getList();
    },

    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },

    // 查询
    async getList() {
      let params = { page: this.page, size: this.size };

      if (this.companyName) {
        params.companyName = this.companyName;
      }
      let res = await selectFenceDzwl(params);
        this.pageTotal = res.data.total;
        this.tableData = res.data.data;
        console.log("公司信息", res.data.data);
    },
    //重置
    reset() {
      this.companyName = null;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
#creatSeat {
  .quit-btn {
    position: fixed;
    z-index: 4;
    top: 18px;
    right: 400px;
  }
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 28%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  .uploadBtn {
    width: 68%;
  }
}
</style>